import React, { useState, useEffect } from "react";
import Preloader from "./components/Preloader";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
// import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Resume from "./components/Resume/ResumeNew";
import Certification from "./components/Certification/Certification";
import Experience from "./components/Experience/Experience";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import ScrollToTop from "./components/ScrollToTop";
import Publications from "./components/Publications/Publications";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      {load ? (
        <Preloader load={load} />
      ) : (
        <div className="App" id={load ? "no-scroll" : "scroll"}>
          <Navbar />
          <ScrollToTop />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/project" component={Projects} />
            {/* <Route path="/about" component={About} /> */}
            <Route path="/publications" component={Publications} />
            <Route path="/resume" component={Resume} />
            <Route path="/certi" component={Certification} />
            <Route path="/experience" component={Experience} />
          </Switch>
          
        </div>
      )}
    </Router>
  );
}

export default App;
