import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { PUBLICATIONS } from "../../Constants"; // Your JSON data imported here
import "./Publications.css";

function Publications() {
  const publicationTypes = Array.from(new Set(PUBLICATIONS.map(pub => pub.type)));

  return (
    <Container fluid className="publications-section">
      <Container>
        <Row className="justify-content-center">
          <h1 className="project-heading mt-5">
            <strong>MY PUBLICATIONS</strong>
          </h1>
          {/* <p className="text-center mb-4">
            For more, please visit my <a href="https://scholar.google.com" target="_blank" rel="noopener noreferrer">Google Scholar</a> or <a href="https://www.researchgate.net" target="_blank" rel="noopener noreferrer">Research Gate</a>
          </p> */}

          {publicationTypes.map((type, typeIndex) => (
            <div key={typeIndex}>
              <h2 className="section-title mt-4">{type}</h2>
              {PUBLICATIONS.filter(pub => pub.type === type).map((pub, index) => (
                <Col key={index} xs={12} className="mb-3">
                  <p className="publication-item">
                    {pub.authors.split(', ').map((author, authorIndex) => (
                      <React.Fragment key={authorIndex}>
                        {(author === "Duc Dat Pham" || author === "Duc Dat Pham*") ? <strong>{author}</strong> : author}
                        {authorIndex !== pub.authors.split(', ').length - 1 && ', '}
                      </React.Fragment>
                    ))}, "<strong>{pub.title}</strong>", {pub.info}. <a href={pub.url} target="_blank" rel="noopener noreferrer">[Link]</a>
                  </p>
                </Col>
              ))}
            </div>
          ))}
        </Row>
      </Container>
    </Container>
  );
}
export default Publications;